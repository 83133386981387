import React from 'react';
import RichText from "../richText";
import styled from 'styled-components';

const ContainerWrapper = styled.section`
	width: 100%;
	max-width: 900px;
	margin: 0 auto;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.5);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 35px;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: var(--heading-fontSize);
		color: var(--primary-color);
	}

	p {
		font-size: var(--body-fontSize);
	}

	@media only screen and (max-width: 450px) {
		padding: 0;
	}
`;

const FormWrapper = styled.form`
	width: 100%;
	max-width: 800px;

	input {
		width: 100%;
		max-width: 500px;
		margin-bottom: 15px;
	}

	textarea {
		margin: 10px 0;
		width: 100%;
		max-width: 650px;
	}
`;

const Button = styled.button`
	background: var(--alt-text);
	background-color: var(--primary-color);
	cursor: pointer;
	padding: 4px 8px;
	box-shadow: none;
	border-radius: 4px;
	color: white;
`;

const Form = ({ name, heading, subHeading, description, fields, action, state }) => {
	console.log(state.booking);
	return (
		<ContainerWrapper>
			{!!heading?.[0]?.text && <RichText render={heading} />}
			{!!subHeading?.[0]?.text && <RichText render={subHeading} />}
			{!!description?.[0]?.text && <RichText render={description} />}
			{!!state.booking && (
				<h3
					style={{
						display       : 'flex',
						flexDirection : 'column',
						fontSize      : 'var(--subHeading-fontSize)'
					}}>
					Therapy:{' '}
					<span
						style={{
							backgroundColor : 'var(--primary-color)',
							color           : 'var(--alt-color)',
							padding         : '5px'
						}}>
						{state.booking}
					</span>
				</h3>
			)}
			<FormWrapper
				name={name}
				method="POST"
				data-netlify="true"
				action={`/${action._meta.uid}`}>
				{!!state.booking && (
					<input type="hidden" name={state.booking} value={state.booking} />
				)}
				<input type="hidden" name="form-name" value={name} />
				{fields.map((field, i) => {
					if (field.field_type === 'textarea') {
						return (
							<div key={i}>
								<textarea
									name={field.field_name}
									cols="30"
									rows="10"
									required={field.required === 'yes'}
									placeholder={field.field_name}
								/>
							</div>
						);
					} else {
						return (
							<div key={i}>
								<input
									name={field.field_name}
									type={field.field_type}
									required={field.required === 'yes'}
									placeholder={field.field_name}
								/>
							</div>
						);
					}
				})}
				<Button type="submit">Submit</Button>
			</FormWrapper>
		</ContainerWrapper>
	);
};

Form.defaultProps = {
	state : {booking: undefined}
}

export default Form;
