import React from 'react';
import RichText from "../richText";
import styled from 'styled-components';
import Button from "../common/button";
import Image from "../prismicImage";

const ContainerWrapper = styled.section`
	display: flex;
	width: 100%;
	height: 300px;
	text-align: center;
	padding: 10px 5px;
	position: relative;

	.info {
		z-index: var(--normal-position);
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	h1,
	h2 {
		font-size: var(--heading-fontSize);
		color: var(--primary-color);
		margin-top: 10px;
	}

	h3,
	h4,
	h5,
	h6 {
		color: var(--primary-color);
		font-size: var(--subHeading-fontSize);
	}

	p {
		font-size: var(--body-fontSize);
		color: #fff;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
		width: 100% !important;
		height: 100% !important;
		z-index: var(--background-position);
	}

	@media only screen and (max-width: 850px) {
		padding: 30px;
		height: fit-content;
	}
`;

const CallToAction = ({
	heading,
	subHeading,
	description,
	ctaLabel,
	ctaAction,
	image
}) => {
	return (
		<ContainerWrapper>
			<div className="info">
			{!!heading?.[0]?.text && <RichText render={heading} />}
			{!!subHeading?.[0]?.text && <RichText render={subHeading} />}
			{!!description?.[0]?.text && <RichText render={description} />}
			{!!ctaLabel && <Button link={ctaAction} text={ctaLabel}/>}
			</div>
			{!!image && <Image src={image.url} effects={["&blur=20"]}/>}
		</ContainerWrapper>
	);
};

export default CallToAction;
