import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

const ButtonWrapper = styled.button`
	display: inline-block !important;
	max-width: 500px !important;
	background-color: var(--primary-color) !important;
	color: var(--alt-color) !important;
	font-size: var(--action-fontSize) !important;
	padding: var(--action-padding) !important;
	margin-top: 20px !important;
	margin-bottom: 20px !important;
	border: 0 !important;
	border-radius: 15px !important;
	outline: 0 !important;
	text-decoration: none !important;
	cursor: pointer !important;

	&.sm {
		font-size: var(--body-fontSize) !important;
		width: fit-content !important;
		padding: 10px !important;
	}

	&.md {
		width: 100%;
		max-width: 500px !important;
	}

	&.lg {
		width: 100%;
		max-width: 100% !important;
	}

	&.center {
		display: block !important;
		margin-right: auto !important;
		margin-left: auto !important;
	}
`;

const Button = ({ className, link, text, stateInfo }) => {
	const handleClick = () => {
		if (!stateInfo) {
			console.log('normal navigate');
			navigate(link);
		} else {
			console.log('stateful navigate', stateInfo);
			navigate(link, { state: { stateInfo } });
		}
	};

	return (
		<ButtonWrapper className={className} onClick={handleClick}>
			{text}
		</ButtonWrapper>
	);
};

export default Button;
