import React from 'react';
import styled from 'styled-components';
import RichText from "../richText";
import Button from "../common/button";
import Image from "../prismicImage";

const SplitLandingWrapper = styled.section`
	width: 100%;
	display: flex;
	flex-wrap: wrap-reverse;
	justify-content: space-between;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		width: 100%;
		color: var(--primary-color);
		font-weight: var(--heading-fontWeight);
		font-size: var(--landing-fontSize);
	}

	p {
		font-size: var(--body-fontSize);
		padding: var(--body-padding);
	}

	img {
		flex: 1 1 0.5em;
		object-position: top center;
		object-fit: cover;
		width: 100%;
		min-width: 400px;
		align-self: flex-end;
		margin: 0;
		height: 80vh;
	}

	@media only screen and (max-width: 850px) {
		img {
			height: 30vh;
		}
  }
  
  @media only screen and (max-width: 400px) {    
    p {
      line-height: 1.2rem;
      margin: 0;
    }
    
    img {
      min-width: 200px;
		}
		
		@media only screen and (max-width: 350px) {
			img{
				height: 12vh;
			}
		}
`;

const Info = styled.div`
	flex: 2 2 20em;
	display: flex;
	flex-direction: column;
	padding: 2rem;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	min-width: 500px;
	text-align: center;

	@media only screen and (max-width: 500px) {
		min-width: 0;
	}
`;

const SplitLanding = ({
	heading,
	subHeading,
	description,
	image,
	ctaLabel,
	ctaAction
}) => {
	return (
		<SplitLandingWrapper>
			<Info>
				{!!heading?.[0]?.text && <RichText render={heading} />}
				{!!subHeading?.[0]?.text && <RichText render={subHeading} />}
				{!!description?.[0]?.text && <RichText render={description} />}
				{!!ctaLabel && <Button link={ctaAction} text={ctaLabel}/>}
			</Info>
			<Image src={image.url} width="100%"/>
		</SplitLandingWrapper>
	);
};

export default SplitLanding;
