import React from 'react';
import RichText from '../richText';
import styled from 'styled-components';
import Card from './cardWithOverlay';
import Button from '../common/button';

const ContainerWrapper = styled.section`
	width: 100%;
	height: 100%;
	text-align: center;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: var(--primary-color);
		font-size: var(--heading-fontSize);
	}

	p {
		font-size: var(--body-fontSize);
		margin-bottom: 0;
	}
`;

const ListWrapper = styled.ul`
	display: grid;
	grid-gap: .8em 1em;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-auto-rows: 280px;
	justify-content: center;
	list-style-type: none;
	margin: 0;

	@media only screen and (max-width: 702px) {
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
		grid-auto-rows: 180px;
	}
`;

const ListOfCards = ({
	heading,
	subHeading,
	description,
	ctaLabel,
	ctaAction,
	fields,
	fieldKey
}) => {
	console.log(ctaAction);
	console.log(heading);
	return (
		<ContainerWrapper>
			{!!heading && <RichText render={heading} />}
			{!!subHeading && <RichText render={subHeading} />}
			{!!description && <RichText render={description} />}
			<Button className="md" link={ctaAction} text={ctaLabel} />
			<ListWrapper>
				{fields.map((el, i) => {
					return (
						<li key={`card-${i}`}>
							<Card
								heading={el[fieldKey].heading}
								subHeading={el[fieldKey].subHeading}
								description={el[fieldKey].description}
								image={el[fieldKey].image.url}
								ctaAction={`/${fieldKey}/${el[fieldKey]._meta.uid}`}
							/>
						</li>
					);
				})}
			</ListWrapper>
		</ContainerWrapper>
	);
};

export default ListOfCards;
