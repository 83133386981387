import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const ItemWrapper = styled.li`
	width: 100%;
	height: 300px;
	position: relative;
	border: 2px solid white;
	cursor: pointer;
	list-style: none;
	color: var(--alt-text);

	a {
		color: white;
	}

	&:hover {
		.info {
			visibility: visible;
		}
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.info {
		visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 25px;

		p {
			font-size: 30px;
		}

		span {
			font-size: 20px;
		}
	}
`;

const ArticleItem = ({ heading, subHeading, description, image, cta }) => {
	return (
		<ItemWrapper>
			<Link to={cta}>
				<img src={image.url} alt="article" />
				<div className="info">
					<p>{heading}</p>
					<span>{subHeading}</span>
				</div>
			</Link>
		</ItemWrapper>
	);
};

export default ArticleItem;
