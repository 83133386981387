import React from 'react';
import RichText from "../richText";
import styled from 'styled-components';
import Button from '../common/button';

const ContainerWrapper = styled.section`
	width: 100%;
	height: fit-content;
	padding: 20px 0 40px 0;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;

	h1,h2,h3,h4,h5.h6 {
		color: var(--primary-color);
	}

	h1,h2 {
		margin-top: 20px;
		font-size: var(--heading-fontSize);
	}

	h3,h4,h5,h6 {
		font-size: var(--subHeading-fontSize);
	}

	p{
		font-size: var(--body-fontSize);
	}

	.textContainer {
		width: 80%;
		max-width: 800px;
		background: rgba(0,0,0,0.1);
		margin: 0 auto;
		text-align: left;
		padding: 20px 40px;
		border: 1px solid rgba(54, 54, 54, 0.47);
		box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 14px 5px;
	}
`;

const NewsBlock = ({ heading, subHeading, description, ctaLabel, ctaAction}) => {
	return (
		<ContainerWrapper>
			{!!heading?.[0]?.text && <RichText render={heading} />}
			<div className="textContainer">
			{!!subHeading?.[0]?.text && <RichText render={subHeading} />}
			{!!description?.[0]?.text && <RichText render={description} />}
			{!!ctaLabel && <Button className="sm" link={ctaAction} text={ctaLabel}/>}
			</div>
		</ContainerWrapper>
	);
};

export default NewsBlock;
