import React from 'react';
import RichText from "../richText";
import styled from 'styled-components';
import Button from "../common/button";

const HeroWrapper = styled.section`
position: relative;
background: url('${(props) => props.backgroundImage}');
background-size: cover;
background-position: center;
background-repeat: no-repeat;
height: calc(90vh - 100px);
display: flex;
align-items: center;
text-align: center;
width: 100%;

h1,h2,h3,h4,h5.h6{
	color: var(--primary-color);
}

h1,h2 {
	font-size: var(--landing-fontSize);
}

h3,h4,h5,h6.h7{
	font-size: var(--heading-fontSize);
}

p {
	font-size: var(--body-fontSize);
	color: var(--alt-color);
}

.overlay{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(255,255,255,0.5);
	z-index: 1;
}

.innerContainer{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 800px;
	height: 100%;
	margin: 0 auto;
	padding: 20px;
	z-index: 2;
	>p{
		font-size: 35px;
	}
}
`;

const Hero = ({heading, subHeading, description, ctaLabel, ctaAction, image}) => {
	console.log(heading, subHeading, description);
	return (
		<HeroWrapper backgroundImage={image.url}>
			<div className="innerContainer">
				{!!heading?.[0]?.text && <RichText render={heading}/>}
				{!!subHeading?.[0]?.text && <RichText render={subHeading}/>}
				{!!description?.[0]?.text && <RichText render={description}/>}
				{!!ctaLabel && <Button link={ctaAction} text={ctaLabel}/>}
			</div>
			<div className="overlay" />
		</HeroWrapper>
	);
};

export default Hero;
