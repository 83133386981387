import React from 'react';
import RichText from '../richText';
import styled from 'styled-components';
import ArticleItem from './listArticleItem';

const ContainerWrapper = styled.section`
	width: 100%;
	padding-top: 50px;
	paddin-bottom: 100px;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: var(--primary-color);
		font-size: var(--heading-fontSize);
		text-align: center;
		width: 300px;
		margin: 0 auto;
	}
`;

const ListWrapper = styled.ul`
	max-width: 1400px;
	margin: 0 auto;
	padding-top: 50px;

	li:nth-child(even) {
		flex-direction: row-reverse;
	}

	@media only screen and (max-width: 450px) {
		padding-top: 20px;
	}
`;

const ArticleList = ({ heading, subHeading, description, fields }) => {
	console.log(fields);
	return (
		<ContainerWrapper>
			<RichText render={heading} />
			<RichText render={subHeading} />
			<RichText render={description} />
			<ListWrapper>
				{fields.map((item, i) => (
					<ArticleItem
						key={`ArticleList${i}`}
						heading={item.heading}
						subHeading={item.subheading}
						description={item.description}
						image={item.image}
						ctaLabel={item.cta_label}
						ctaAction={item.cta_action}
					/>
				))}
			</ListWrapper>
		</ContainerWrapper>
	);
};

export default ArticleList;
