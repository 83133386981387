import React from 'react';
import RichText from "../richText";
import styled from 'styled-components';
import Button from "../common/button";

const ContainerWrapper = styled.section`
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;
	padding: 20px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	h1,h2,h3,h4,h5,h6 {
		color: var(--primary-color);
	}

	h1,h2 {
		font-size: var(--heading-fontSize);
	}

	h3,h4,h5,h6 {
		font-size: var(--subHeading-fontSize);
	}

	p{
		font-size: var(--body-fontSize);
		padding: 0 25px;
	}
`;

const InfoBlock = ({ heading, subHeading, description, ctaLabel, ctaAction }) => {
	return (
		<ContainerWrapper>
			{!!heading?.[0]?.text && <RichText render={heading} />}
			<div className="inner">
			{!!subHeading?.[0]?.text && <RichText render={subHeading} />}
			{!!description?.[0]?.text && <RichText render={description} />}
			</div>
			{!!ctaLabel && <Button link={ctaAction} text={ctaLabel}/>}
		</ContainerWrapper>
	);
};

export default InfoBlock;
