import React from 'react';
import {
	NewsBlock,
	Hero,
	ArticleList,
	InfoBlock,
	Form,
	CallToAction,
	ListOfCards,
	SplitLanding
} from './sliceZone/';

const SliceZone = ({ body, location }) => {
	// console.log(body);
	return (
		<>
			{body.map((bodyContent, i) => {
				if(bodyContent.type === "split_landing"){
					return (
						<SplitLanding key={i} heading={bodyContent.primary.heading} subHeading={bodyContent.primary.subheading} description={bodyContent.primary.description} image={bodyContent.primary.image} ctaLabel={bodyContent.primary.cta_label} ctaAction={bodyContent.primary.cta_action}/>
					)
				}
				if (bodyContent.type === 'hero') {
					return (
						<Hero
							key={i}
							heading={bodyContent.primary.heading} subHeading={bodyContent.primary.subheading} description={bodyContent.primary.description} image={bodyContent.primary.image} ctaLabel={bodyContent.primary.cta_label} ctaAction={bodyContent.primary.cta_action}
						/>
					);
				} else if (bodyContent.type === 'news_block') {
					return (
						<NewsBlock
							key={i}
							heading={bodyContent.primary.heading} subHeading={bodyContent.primary.subheading} description={bodyContent.primary.description} ctaLabel={bodyContent.primary.cta_label} ctaAction={bodyContent.primary.cta_action}
						/>
					);
				}else if(bodyContent.type === "form"){
					console.log("form sliceZone");
					return (
						<Form key={i} name={bodyContent.primary.form_name} heading={bodyContent.primary.heading} subHeading={bodyContent.primary.subheading} description={bodyContent.primary.description} fields={bodyContent.fields} action={bodyContent.primary.action} state={location.state ? location.state.stateInfo : undefined}></Form>
					)
				} else if(bodyContent.type === "call_to_action"){
					return(
						<CallToAction key={i} heading={bodyContent.primary.heading} subHeading={bodyContent.primary.subheading} description={bodyContent.primary.description} ctaLabel={bodyContent.primary.cta_label} ctaAction={bodyContent.primary.cta_action} image={bodyContent.primary.image}/>
						)
				} else if(bodyContent.type === "list_of_cards"){
					return (
						<ListOfCards key={i} heading={bodyContent.primary.heading} subHeading={bodyContent.primary.subHeading} description={bodyContent.primary.description} fields={bodyContent.fields}/>
						)
				} else if(bodyContent.type === "list_of_therapies"){
					return (
						<ListOfCards key={i} heading={bodyContent.primary.heading} subHeading={bodyContent.primary.subHeading} description={bodyContent.primary.description} ctaLabel={bodyContent.primary.cta_label} ctaAction={bodyContent.primary.cta_action} fields={bodyContent.fields} fieldKey="therapy"/>
					)
				} else if(bodyContent.type === "list_of_workshops") {
					return (
						<ListOfCards key={i} heading={bodyContent.primary.heading} subHeading={bodyContent.primary.subHeading} description={bodyContent.primary.description} fields={bodyContent.fields} fieldKey="workshop"/>
					)
				} else if(bodyContent.type === "list_of_articles1"){
					return (
						<ArticleList key={i} heading={bodyContent.primary.heading} subHeading={bodyContent.primary.subHeading} description={bodyContent.primary.description} fields={bodyContent.fields}/>
					)
				} else if(bodyContent.type ===  "info_block"){
					return (
						<InfoBlock key={i} heading={bodyContent.primary.heading} subHeading={bodyContent.primary.subheading} description={bodyContent.primary.description} ctaLabel={bodyContent.primary.cta_label} ctaAction={bodyContent.primary.cta_action}/>
					)	
				}else {
				return (
				<h3 style={{textAlign: "center", margin: "30px 0"}} key={i}>Error Loading extra content: <span style={{color: "red"}}>{bodyContent.type}</span></h3>
				)
				}
			})}
		</>
	);
};

export default SliceZone;
