import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import Image from '../prismicImage';

const ItemWrapper = styled.li`
	width: 100%;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	cursor: pointer;

	.info {
		flex: 2 2 20em;
		display: flex;
		flex-direction: column;
		padding: 2rem;
		justify-content: center;
		align-items: center;
		flex-grow: 1;
		min-width: 500px;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size: var(--heading-fontSize);
			text-align: left;
			width: 100%;
			padding: 0;
			margin: 0;
			float: left;
			display: inline;
		}

		a {
			text-decoration: none;
		}

		span {
			font-size: var(--subHeading-fontSize);
			color: var(--text-color);
		}

		p {
			font-size: var(--body-fontSize);
			line-height: 1.8rem;
			color: var(--text-color);
		}

		@media only screen and (max-width: 500px) {
			min-width: 100%;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				line-height: 2rem;
				margin-bottom: 1.5rem;
			}

			span {
				line-height: 1.5rem;
			}
		}
	}

	img {
		flex: 1 1 0.5em;
		object-position: center center;
		object-fit: cover;
		min-width: 400px;
		max-width: 400px;
		min-height: 500px;
		max-height: 500px;
		margin: 0 auto;

		@media only screen and (max-width: 916px) {
			object-position: center center;
			min-width: 300px;
			max-width: 100%;
			min-height: 250px;
			max-height: 250px;
		}

		@media only screen and (max-width: 450px) {
			min-height: 150px;
			max-height: 150px;
		}
	}
`;

const ArticleItem = ({
	heading,
	subHeading,
	description,
	image,
	ctaLabel,
	ctaAction
}) => {
	const [ size, setSize ] = useState(
		typeof window !== 'undefined' ? window.innerWidth : 1000
	);

	const handleSizeChange = (e) => {
		setSize((state) => e.target.innerWidth);
	};

	useEffect(
		() => {
			window.addEventListener('resize', (e) => handleSizeChange(e));
		},
		[ size ]
	);

	console.log(ctaAction);

	return (
		<ItemWrapper
			onClick={(e) => {
				navigate(ctaAction);
			}}>
			<div className="info">
				<Link to={ctaAction}>
					{!!heading && (
						<h2>
							{heading[0].text}{' '}
							{!!subHeading && <span>{subHeading[0].text}</span>}
						</h2>
					)}
					{!!description && (
						<p>
							{size < 600 ? (
								description[0].text.slice(0, 55).concat('... MORE')
							) : size > 916 ? (
								description[0].text
							) : (
								description[0].text.slice(0, 100).concat('... MORE')
							)}
						</p>
					)}
					{/* {!!description && <RichText render={description} />} */}
				</Link>
			</div>
			{!!image && (
				<Image
					src={image.url}
					alt={heading[0].text}
					sizes={[ 100, 200, 300, 400 ]}
				/>
			)}
		</ItemWrapper>
	);
};

export default ArticleItem;
