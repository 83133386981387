import React from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import Image from '../prismicImage';

const ContainerWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	cursor: pointer;

	img {
		position: absolute;
		width: 100% !important;
		height: 100% !important;
		top: 0;
		left: 0;
		object-fit: cover !important;
	}

	h2 {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		color: var(--text-color) !important;
		text-shadow: 0.8px 0px 0px white, -0.8px 0px 0px white, 0px 0.8px 0px white,
			0px -0.8px 0px white;
		z-index: 10;
		pointer-events: none;
		padding: 5px;

		@media only screen and (max-width: 650px) {
			font-size: var(--subHeading-fontSize) !important;
		}
	}

	.overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(255, 255, 255, 0.75);
		z-index: 5;

		&:hover {
			border: 3px solid var(--primary-color);
		}
	}
`;

const cardWithOverlay = ({
	heading,
	subHeading,
	description,
	image,
	ctaLabel,
	ctaAction
}) => {
	return (
		<Link to={ctaAction}>
			<ContainerWrapper>
				<div className="overlay" />
				<h2>{heading[0].text}</h2>
				<Image src={image} sizes={[ 100, 200 ]} quality={30} />
			</ContainerWrapper>
		</Link>
	);
};

export default cardWithOverlay;
