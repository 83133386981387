import React from 'react';
import RichText from '../richText';
import styled from 'styled-components';
import ArticleItem from './gridArticleItem';

const ContainerWrapper = styled.section`
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	padding: 30px 0 30px 0;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: var(--primary-color);
		font-size: 2.5rem;
		width: 300px;
		margin: 0 auto;
	}
`;

const ArticleWrapper = styled.ul`
	display: grid;
	grid-gap: 1em;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-auto-rows: 330px;
	width: 80%;
	margin: 0 auto;
	max-width: 900px;
`;

const ArticleList = ({ title, articles }) => {
	return (
		<ContainerWrapper>
			<RichText render={title} />
			<ArticleWrapper>
				{articles.map((article, i) => {
					return (
						<ArticleItem
							heading={article.article_title}
							subHeading={article.subtitle}
							description={article.description}
							image={article.article_thumbnail}
							cta={'/'}
							key={i}
						/>
					);
				})}
			</ArticleWrapper>
		</ContainerWrapper>
	);
};

export default ArticleList;
